import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgcSidenavComponent, NgcSideNavElement } from 'ngc';
import { BehaviorSubject } from 'rxjs';
import { CagedComponent } from '../../modules/bases/components/caged/caged.component';
import { CagedService } from '../../modules/bases/components/caged/services/caged.service';
import { Caged } from '../../modules/bases/components/models/caged';
import { InformacaoSistema } from './../../../../shared/shared-models/model/informacao-sistema';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-public-content',
  templateUrl: './public-content.component.html',
  styleUrls: ['./public-content.component.scss']
})
export class PublicContentComponent implements OnInit {
  menuElements: NgcSideNavElement[] = [
    {
      title: 'Bases',
      aberto: true,
      icon: 'aspect_ratio',
      url: 'ngc-form/ngc-input',
      elements: [
        {
          url: '/public/bases/cgil',
          title: 'CGIL',
        },
        {
          url: '/public/bases/ctps',
          title: 'CTPS',
        },
        {
          url: '/public/bases/rais',
          title: 'RAIS',
        },
        {
          url: '/public/bases/caged',
          title: 'CAGED',
        },
        {
          url: '/public/bases/stimar',
          title: 'SOLICITANTES DE REFÚGIO',
        },
        {
          url: '/public/bases/sti',
          title: 'STI',
        },
        {
          url: '/public/bases/sisMigraAnoRegistro',
          title: 'SISMIGRA ANO REGISTRO',
        },
        {
          url: '/public/bases/sisMigraAnoEntrada',
          title: 'SISMIGRA ANO ENTRADA',
        }
      ]
    },
    {
      title: 'SOBRE',
      aberto: false,
      icon: 'perm_device_information',
      url: '/public/bases/sobre',
     
    },
    
    {
      title: 'TUTORIAL',
      aberto: false,
      icon: 'question_answer',
      url: '/public/bases/tutorial',
     
    },
    {
      title: 'NOTAS METODOLÓGICAS',
      aberto: false,
      icon: 'info',
      url: '/public/bases/notas',
      
     
    }
  ];
  informacoes: InformacaoSistema;
  @ViewChild('sidenav') sidenav: NgcSidenavComponent;

  caged: CagedComponent;

  constructor(@Inject('InformacaoSistema') sistema: BehaviorSubject<InformacaoSistema>,private cagedService: CagedService) {
    sistema.subscribe((infos) => {
      this.informacoes = infos;
    });
    
    
  }
  ngOnInit() {
    
  }
  
  anosCaged: Caged[];

  clickano(){
    var anos;
    this.cagedService.getList().subscribe((caged: Caged[]) => {
      anos=caged;
      this.anosCaged=caged;
    });

    return anos;
  }
  
  onLogout() {

  }
  changeSidenav($event) {
    this.sidenav.toggleSidenav();
  }
}

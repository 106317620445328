import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicContentComponent } from './components/public-content/public-content.component';

/*Rotas públicas*/
const routes: Routes = [
  
  {
    path: 'public',
    component: PublicContentComponent,
    children: [
      {
        path: 'bases',
        loadChildren:
          './modules/bases/bases.module#BasesModule',
        data: {
          breadcrumb: 'Início'
        }
      },{
        path: '',
        loadChildren:
          './modules/bases/bases.module#BasesModule',
        data: {
          breadcrumb: 'Início'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
  ],
})
export class PublicRoutingModule { }

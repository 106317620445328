import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule, MatIconModule, MatSidenavModule, MatToolbarModule } from '@angular/material';
import { NgcAccordionModule, NgcHeaderModule, NgcSidenavModule, NgcButtonModule } from 'ngc';
import { PrivateContentComponent } from './components/private-content/private-content.component';
import { PrivateRoutingModule } from './private-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreModule } from '../core/core.module';


@NgModule({
  imports: [
    CommonModule,
    PrivateRoutingModule,
    MatSidenavModule,
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    NgcHeaderModule,
    NgcSidenavModule,
    NgcAccordionModule,
    NgcButtonModule,
    FlexLayoutModule,
    CoreModule
  ],
  declarations: [
    PrivateContentComponent
  ],
  providers: [
  ]
})
export class PrivateModule { }

import { TokenGuardService } from './../core/services/token-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateContentComponent } from './components/private-content/private-content.component';

/** Rotas privadas da aplicação */
const routes: Routes = [
  {
    path: 'private',
    component: PrivateContentComponent,
    children: [
      {
        path: 'exemplo',
        loadChildren:
          './modules/exemplo/exemplo.module#ExemploModule',
        data: {
          breadcrumb: 'Início'
        }
      },
      {
        path: 'usuario',
        loadChildren: './modules/usuario/usuario.module#UsuarioModule',
        data: {
          breadcrumb: 'Usuário'
        }
      }
    ],
    data: {
      breadcrumb: 'Início'
    },
    canActivate: [TokenGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }

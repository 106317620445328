import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionStorageKey } from 'src/app/shared/shared-models/enums/session-storage-key.enum';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private sessionStorageService: SessionStorageService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = null;
    const storagedToken = this.sessionStorageService.getItem(SessionStorageKey.TOKEN);
    if (storagedToken !== null) {
      token = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${storagedToken}`)
      });
    }
    return next.handle(token !== null ? token : request);
  }
}

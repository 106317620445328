// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://datamigraweb.unb.br/api/',
    recaptcha: {
//       siteKey: '6LfKNi0cAAAAACeYwFRY9_d_qjGhpiwYUo5gNW5-',
//         siteKey: '6LdF2rAoAAAAAL0y2JjWGVt23mmrGgxOME7QqR2L',
//	siteKey: '6LdF2rAoAAAAAL1UtVhX2QkrpAl0NvelyxQ78JqX',
	siteKey: '6LdyTSgpAAAAAPodBUHBnDdLtrNxb6FXMC7uSqPG',
      },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MensagemRetorno } from 'src/app/shared/shared-models/model/mensagem-retorno';
import { Caged } from '../../models/caged';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { RequestOptions } from '@angular/http';


const PATH = {
  caged: '/caged'
};

@Injectable({
  providedIn: 'root'
})
export class CagedService {
  constructor(private http: HttpClient) { }
  putCaged(id, caged: Caged): Observable<MensagemRetorno> {
    return this.http.put<MensagemRetorno>(`${environment.apiUrl}${PATH.caged}/${id}`, caged);
  }
  get(id): Observable<Caged> {
    return this.http.get<Caged>(`${environment.apiUrl}${PATH.caged}/${id}`);
  }


  getAnos(anos, meses, unidade_da_federacao, continentes, municipio, paises, tipo_de_movimentacao, SEXO, pagina, totalPagina): Observable<Caged[]> {

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    var body = { 'ano':anos,'mes':meses,'unidade_da_federacao':unidade_da_federacao,'pais':paises,'continente':continentes,'municipio':municipio,'tipo_de_movimentacao':tipo_de_movimentacao,'sexo':SEXO};
    

    return this.http.post<Caged[]>(`${environment.apiUrl}${PATH.caged}/busca`, body, options).pipe(map((result: any) => result.list));
  }

  /*getAnos(anos,meses,unidade_da_federacao,continentes,municipio,paises,tipo_de_movimentacao,SEXO,pagina,totalPagina): Observable<Caged[]> {
   return this.http.get<Caged[]>(`${environment.apiUrl}${PATH.caged}/${anos}/${meses}/${unidade_da_federacao}/${continentes}/${paises}/${municipio}/${tipo_de_movimentacao}/${SEXO}/${pagina}/${totalPagina}`).pipe(map((result:any)=>result.list));
  }*/
  getList(): Observable<Caged[]> {
    return this.http.get<Caged[]>(`${environment.apiUrl}${PATH.caged}`).pipe(map((result: any) => result.list));
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BehaviorSubject } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { PrivateModule } from './modules/private/private.module';
import { PublicModule } from './modules/public/public.module';
import { InformacaoSistema } from './shared/shared-models/model/informacao-sistema';
import { HttpClientModule } from '@angular/common/http';


import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PrivateModule,
    PublicModule,
    HttpClientModule,
    CoreModule,
    FormsModule
  ],
  providers: [
    { provide: 'InformacaoSistema', useValue: new BehaviorSubject(new InformacaoSistema()) }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

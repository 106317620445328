export class InformacaoSistema {
  nome: string;
  nomeExibido: string;
  matricula: string;
  sistema: string;
  sistemaDescricao: string;
  versaoBackend: string;
  varsaoFrontend: string;
  id?: number;
}

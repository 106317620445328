import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule, MatIconModule, MatSidenavModule, MatToolbarModule,MatTabsModule  } from '@angular/material';
import { NgcAccordionModule, NgcHeaderModule, NgcSidenavModule, NgcButtonModule } from 'ngc';
import { PublicContentComponent } from './components/public-content/public-content.component';
import { PublicRoutingModule } from './public-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreModule } from '../core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';



@NgModule({
  imports: [
    CommonModule,
    PublicRoutingModule,
    MatSidenavModule,
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    NgcHeaderModule,
    NgcSidenavModule,
    NgcAccordionModule,
    NgcButtonModule,
    FlexLayoutModule,
    CoreModule,
    NgbModule,
    BrowserModule,
    FormsModule
  ],
  declarations: [
    PublicContentComponent
  ],
  providers: [
  ]
})
export class PublicModule { }

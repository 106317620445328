import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgcSidenavComponent, NgcSideNavElement } from 'ngc';
import { BehaviorSubject } from 'rxjs';
import { InformacaoSistema } from './../../../../shared/shared-models/model/informacao-sistema';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-private-content',
  templateUrl: './private-content.component.html',
  styleUrls: ['./private-content.component.scss']
})
export class PrivateContentComponent implements OnInit {
  menuElements: NgcSideNavElement[] = [
    {
      title: 'Usuário',
      aberto: false,
      icon: 'aspect_ratio',
      url: 'ngc-form/ngc-input',
      elements: [
        {
          url: '/private/usuario/complementar-dados',
          title: 'Complementar dados',
        }
      ]
    }
  ];
  informacoes: InformacaoSistema;
  @ViewChild('sidenav') sidenav: NgcSidenavComponent;

  constructor(@Inject('InformacaoSistema') sistema: BehaviorSubject<InformacaoSistema>) {
    sistema.subscribe((infos) => {
      this.informacoes = infos;
    });
  }
  ngOnInit() {

  }

  onLogout() {

  }
  changeSidenav($event) {
    this.sidenav.toggleSidenav();
  }
}

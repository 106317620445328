import { Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InformacaoSistema } from './shared/shared-models/model/informacao-sistema';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(@Inject('InformacaoSistema') sistema: BehaviorSubject<InformacaoSistema>) {
    const informacoes = {
      nome: 'USUARIO LOGADO',
      nomeExibido: '000000 - USUARIO LOGADO',
      matricula: '000000',
      sistema: 'DataMigra',
      sistemaDescricao: 'Portal de Imigração Laboral',
      versaoBackend: '0.0.0',
      varsaoFrontend: '0.0.0',
    } as InformacaoSistema;
    sistema.next(informacoes);
  }
}
